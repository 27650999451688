<script setup lang="ts">
import ReferralBanner from "~/components/TopBanner/Referrals/ReferralBanner.vue";
import PromoCodeTiers from "~/components/TopBanner/Promos/PromoCodeTiers.vue";
import ReferralRedemptionLimitBanner from "~/components/TopBanner/Referrals/ReferralRedemptionLimitBanner.vue";
import PromoBanner from "~/components/TopBanner/Promos/PromoBanner.vue";

useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind-legacy.css",
    },
  ],
});
</script>
<template>
  <main>
    <client-only>
      <PromoCodeTiers />
      <PromoBanner />
    </client-only>
    <ReferralBanner />
    <ReferralRedemptionLimitBanner />
    <slot />
  </main>
</template>
